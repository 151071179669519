<template>
    <div class="flex justify-center my-10">
        <SimulatorBase
            class="phone-wrapper relative"
            :screen="screen"
        />
    </div>
</template>

<script>
import {
    mapState,
} from 'vuex';

import SimulatorBase from './SimulatorBase.vue';

export default {
    name: 'GlobalSimulator',

    data() {
        return {
            bootTime: 1500,
            screen: 'SplashScreen',
        };
    },

    computed: {
        ...mapState('simulator', [
            'connected',
        ]),
    },

    watch: {
        connected(newValue) {
            if (newValue === true) {
                this.screen = 'MainScreen';
            }
        },
    },

    mounted() {
        if (!this.connected && this.screen === 'SplashScreen') {
            setTimeout(() => { this.screen = 'LaunchScreen'; }, this.bootTime);
        }
    },

    components: {
        SimulatorBase,
    },
};
</script>

<style lang="postcss">
.phone-wrapper {
    width: 19rem;
    height: 40rem;
}
</style>
